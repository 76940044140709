import React, { useEffect, useState } from "react";
import { Timeline } from "react-imation/timeline";
import { tween } from "react-imation";
import fetch from "node-fetch";

const ANIMATION_TIME = 200;

export default function MinecraftCheck() {
  const [minecraft_name, setMinecraftName] = useState("");
  const [checked, setChecked] = useState(null);
  const [error, setError] = useState(null);
  const [isOnWhitelist, setIsOnWhitelist] = useState(null);

  const onCheck = () => {
    if (
      !minecraft_name ||
      minecraft_name === "" ||
      minecraft_name.length < 3 ||
      minecraft_name.length > 16
    ) {
      setChecked(false);
      return null;
    } else {
      setChecked(true);
      return;
    }
  };

  const fetchWL = async () => {
    try {
      await fetch(`https://blazeit.sk/api/minecraft-users`, {
        method: "POST",
        body: JSON.stringify({ minecraft_name: minecraft_name }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((data) => {
          if (data["found"] === true) {
            setIsOnWhitelist(true);
          } else {
            setIsOnWhitelist(false);
          }
        });
      });
    } catch (err) {
      setError(err);
      return null;
    }
  };

  useEffect(() => {
    if (checked !== null) {
      fetchWL();
    }
  }, [checked]);

  useEffect(() => {
    if (error !== null) {
      console.error(error);
    }
  }, [error]);

  return (
    <Timeline playOnMount min={0} max={ANIMATION_TIME}>
      {({ time }) => {
        return (
          <div className="kontajner">
            <div className="wrapper">
              <div className="status-wrapper">
                {checked === null && (
                  <>
                    <input
                      className="mcid-input"
                      onChange={(e) => setMinecraftName(e.target.value)}
                      value={minecraft_name}
                      placeholder="Minecraft ID"
                    />

                    <button className="check-button" onClick={() => onCheck()}>
                      <span>Skontroluj</span>
                    </button>
                  </>
                )}

                <p className="check-status">
                  {isOnWhitelist === true && (
                    <span style={{ color: "#70B237" }}>Si na whiteliste</span>
                  )}
                  {isOnWhitelist === false && (
                    <span style={{ color: "#D60007" }}>
                      Nie si na whiteliste
                    </span>
                  )}
                  {error !== null && (
                    <span style={{ color: "#D60007" }}>Vyskytla sa chyba</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </Timeline>
  );
}
