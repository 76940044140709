import React, { useEffect } from "react";
import { findDOMNode } from "react-dom";
import { Timeline } from "react-imation/timeline";

import TraplifeTitle from "./TraplifeTitle";

export default function TraplifeTitleAnimated({ stroke, ...props }) {
  const donepath = [];
  const offsetTarget = [];

  useEffect(() => {
    donepath.forEach((i, index) => {
      const node = findDOMNode(donepath[index]);
      const length = ~~node.getTotalLength();
      offsetTarget[index] = length + 1;
      node.style.strokeDasharray = `${length} ${length}`;
    });
  }, []);

  return (
    <Timeline playOnMount min={0} max={3000}>
      {({ time }) => {
        return (
          <TraplifeTitle
            donepath={donepath}
            offsetTarget={offsetTarget}
            time={time}
            stroke={stroke}
            {...props}
          />
        );
      }}
    </Timeline>
  );
}
