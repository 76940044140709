import React from "react";

import MinecraftCheck from "./MinecraftCheck";
import MinecraftText from "./MinecraftText";
import { VIEWS } from "../enums";
import { ReactComponent as SK } from "../assets/sk.svg";
import { ReactComponent as CZ } from "../assets/cz.svg";

const images = [require("../assets/dirt.png"), require("../assets/paper.png")];

export default function MinecraftContent({ view, setView }) {
  switch (view) {
    case VIEWS.LANDING:
      return (
        <div className="landing-container">
          <div>
            <div onClick={() => setView(VIEWS.COUNTRY)}>
              <img src={images[0]} alt="Pridat sa na Whitelist" />
            </div>
            <button
              className="check-button"
              onClick={() => setView(VIEWS.COUNTRY)}
            >
              <span>Pridat sa na Whitelist</span>
            </button>
          </div>
          <div>
            <div onClick={() => setView(VIEWS.WHITELIST)}>
              <img src={images[1]} alt="Whitelist" />
            </div>
            <button
              className="check-button"
              onClick={() => setView(VIEWS.WHITELIST)}
            >
              <span>Whitelist</span>
            </button>
          </div>
        </div>
      );
    case VIEWS.WHITELIST:
      return <MinecraftCheck />;
    case VIEWS.COUNTRY:
      return (
        <div className="country-container">
          <div>
            <div onClick={() => setView(VIEWS.SK)}>
              <SK />
            </div>
            <button className="check-button" onClick={() => setView(VIEWS.SK)}>
              <span>Iba pre SK telefónne císla</span>
            </button>
          </div>
          <div>
            <div onClick={() => setView(VIEWS.CZ)}>
              <CZ />
            </div>
            <button className="check-button" onClick={() => setView(VIEWS.CZ)}>
              <span>Jen pro CZ telefonní císla</span>
            </button>
          </div>
        </div>
      );
    case VIEWS.SK:
      return <MinecraftText country={VIEWS.SK} />;
    case VIEWS.CZ:
      return <MinecraftText country={VIEWS.CZ} />;
    default:
      break;
  }
}
