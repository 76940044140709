import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import WebFont from "webfontloader";

import Minecraft from "./Minecraft";

// import Pp from "./Pp";
// import Tos from "./Tos";

export default function Router() {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    WebFont.load({
      google: {
        families: ["Josefin Sans:300,400,600,700"],
      },
      fontloading: () => {},
      fontactive: () => {
        setFontLoaded(true);
      },
    });
  }, []);

  if (!fontLoaded) {
    return null;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <Route exact path="/" render={() => <Minecraft />} />

          {/* <Route exact path="/pp" component={Pp} />
          <Route exact path="/tos" component={Tos} /> */}

          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
