import React, { useState, useEffect } from "react";

import { VIEWS, SK_TEXT, CZ_TEXT, PLATFORMS } from "../enums";

const iOS = () => {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);
};

const mobilePlatform = (isIOS) => {
  if (!!isIOS) {
    if (!!window.indexedDB) {
      return PLATFORMS.IOS;
    } else {
      return PLATFORMS.OLD_IOS;
    }
  } else {
    return PLATFORMS.ANDROID;
  }
};

const formatSMS = (country, platform) => {
  if (country === VIEWS.SK) {
    switch (platform) {
      case PLATFORMS.ANDROID:
        return "sms:8866?body=FAKAHEDA%20L147562%201.2%20WHITELIST%20";
      case PLATFORMS.IOS:
        return "sms:8866&body=FAKAHEDA%20L147562%201.2%20WHITELIST%20";
      case PLATFORMS.OLD_IOS:
        return "sms:8866;body=FAKAHEDA%20L147562%201.2%20WHITELIST%20";
      default:
        break;
    }
  } else if (country === VIEWS.CZ) {
    switch (platform) {
      case PLATFORMS.ANDROID:
        return "sms:90733?body=FAKAHEDA%20L147562%2030%20WHITELIST%20";
      case PLATFORMS.IOS:
        return "sms:90733&body=FAKAHEDA%20L147562%2030%20WHITELIST%20";
      case PLATFORMS.OLD_IOS:
        return "sms:90733;body=FAKAHEDA%20L147562%2030%20WHITELIST%20";
      default:
        break;
    }
  }
};

export default function MinecraftText({ country }) {
  const [isMobile, setIsMobile] = useState(false);
  const [MCName, setMCName] = useState("");
  const [sms, setSms] = useState("");

  const isIOS = iOS();
  const platform = mobilePlatform(isIOS);
  const formatedSMS = formatSMS(country, platform);

  useEffect(() => {
    window.innerWidth >= 700 ? setIsMobile(false) : setIsMobile(true);
  }, []);

  useEffect(() => {
    console.log(MCName);

    if (!MCName || MCName === "" || MCName.length < 3 || MCName.length > 16) {
      return;
    } else {
      setSms(formatedSMS + MCName);
    }
  }, [MCName]);

  const renderText = (text) => {
    return (
      <div className="text-container">
        <h4>{text.Info}</h4>
        <div className="warn">
          <p>
            {text.Warning[0]}
            <b>{text.Warning[1]}</b>
            {text.Warning[2]}
          </p>
          <p>
            <b>{text.Warning2[0]}</b>
            {text.Warning2[1]}
            <b>{text.Warning2[2]}</b>
            {text.Warning2[3]}
          </p>
        </div>
        {isMobile ? (
          <div className="kontajner">
            <div className="wrapper">
              <div className="status-wrapper">
                <input
                  className="mcid-input"
                  onChange={(e) => setMCName(e.target.value)}
                  value={MCName}
                  placeholder="Minecraft ID"
                />

                <a
                  href={sms}
                  className="check-button"
                  onClick={() => {
                    console.log(sms);
                    setTimeout(() => {
                      setMCName("");
                    }, 1000);
                  }}
                >
                  <span>Odoslat</span>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="sms">
            <h4>
              {text.SMS[0]} <b>{text.SMS[1]}</b> {text.SMS[2]}{" "}
              <b>{text.SMS[3]}</b> {text.SMS[4]} <b>{text.SMS[5]}</b>
            </h4>
            <em>
              {text.SMSAlt[0]} <b>{text.SMSAlt[1]}</b>
            </em>
          </div>
        )}
        <p>{text.Success}</p>
        <h3>
          {text.Price[0]}
          <b>{text.Price[1]}</b>
          {text.Price[2]}
        </h3>
        <div className="op">
          <em>
            {text.Operator[0]} <b>{text.Operator[1]}</b> {text.Operator[2]}
            <b>{text.Operator[3]}</b>
          </em>
          <em>
            {text.Invoice[0]}
            <b>{text.Invoice[1]}</b>
            {text.Invoice[2]}
          </em>
        </div>
      </div>
    );
  };

  if (country === VIEWS.SK) {
    return renderText(SK_TEXT);
  } else if (country === VIEWS.CZ) {
    return renderText(CZ_TEXT);
  } else {
    return <em>loading...</em>;
  }
}
