export const VIEWS = {
  LANDING: "LANDING",
  COUNTRY: "COUNTRY",
  WHITELIST: "WHITELIST",
  SK: "SK",
  CZ: "CZ",
};

export const SK_TEXT = {
  Info: "Daný postup platí iba pre osoby so Slovenským telefónnym číslom:",
  Warning: [
    "Pre pridanie na ",
    "whitelist TRAPLIFE CRAFT reborn",
    " treba mať originálny Mojang Účet, v opačnom prípade tvoj účet nebude rozpoznaný a ani pridaný, avšak peniaze sa odčítajú a nebude možné ich reklamovať.",
  ],
  Warning2: [
    "Uisti sa,",
    " že máš ",
    "originálny Minecraft Účet",
    " pred pokračovaním k SMS platbe.",
  ],
  SMS: [
    "Pošli ",
    "SMS",
    " na číslo ",
    "8866",
    " v tvare ",
    "FAKAHEDA L147562 1.2 WHITELIST TvojMojangÚčet",
  ],
  SMSAlt: [
    "- teda ak tvoj Mojang Účet je Peter123 tak tvar tvojej SMS bude ",
    "FAKAHEDA L147562 1.2 WHITELIST Peter123",
  ],
  Success:
    "Po úspešnom odoslaní SMS bude tvoj Mojang Účet pridaný v priebehu pár sekúnd od potvrdenia.",
  Price: ["Cena spätnej SMS je ", "1,20 €", " vrátane DPH."],
  Operator: [
    "Prevádzkovateľ služby je ",
    "FakaHeda.eu.",
    " Spoplatnené cez ",
    "PlatbaMobilom.sk.",
  ],
  Invoice: [
    "Žiadosť o faktúru možno získať poslaním e-mailu na adresu ",
    "sales@fakaheda.eu",
    " je potrebné uviesť telefónne číslo, z ktorého sa SMS posielala a kontrolné číslo z SMS odpovede.",
  ],
};

export const CZ_TEXT = {
  Info: "Daný postup platí jen pro osoby s Českým telefonním číslem:",
  Warning: [
    "Pro přidání na ",
    "whitelist TRAPLIFE CRAFT reborn",
    " třeba mít originální Mojang Účet, v opačném případě tvůj účet nebude rozpoznán a ani přidaný, avšak peníze se odečítají a nebude možné je reklamovat.",
  ],
  Warning2: [
    "Ujisti se,",
    " že máš ",
    "originální Minecraft Účet",
    " před pokračováním k SMS platbě.",
  ],
  SMS: [
    "Pošli ",
    "SMS",
    " na číslo ",
    "90733",
    " ve tvaru ",
    "FAKAHEDA L147562 30 WHITELIST TvůjMojangÚčet",
  ],
  SMSAlt: [
    "- tedy pokud tvůj Mojang Účet je Láďa123 tak tvar tvé SMS bude ",
    "FAKAHEDA L147562 30 WHITELIST Láďa123",
  ],
  Success:
    "Po úspěšném odeslání SMS bude tvůj Mojang Účet přidán během pár sekund od potvrzení.",
  Price: ["Cena zpětné SMS je ", "30,00 KČ."],
  Operator: [
    "Provozovatel služby je ",
    "FakaHeda.eu.",
    " *Technicky zajišťuje ",
    "Airtoy a.s., www.platmobilem.cz.",
  ],
  Invoice: [
    "Žádost o fakturu lze získat posláním e-mailu na adresu ",
    "sales@fakaheda.eu",
    " je třeba uvést telefonní Číslo, ze kterého se SMS posílala.",
  ],
};

export const PLATFORMS = {
  ANDROID: "ANDROID",
  IOS: "IOS",
  OLD_IOS: "OLD_IOS",
};
