import React from "react";
import { tween } from "react-imation";

const PATHS = [
  "M0.624864,6.040055 L0.684864,0.689931 L2.387164,5.899857 L2.510594,0.629679 L4.259294,5.779348 L4.476764,0.870693 C4.476764,0.870693 6.16544,6.187999 6.44633,5.705968 C6.72719,5.223935 7.14512,0.870693 7.14512,0.870693 L9.25853,5.813348 L9.88371,0.629679 L11.30852,5.868221 L12.55206,0.810439 L15.22043,4.184661 C15.22043,4.184661 15.15023,0.147645 15.29063,0.629678 C15.43106,1.111708 16.76525,3.823137 16.76525,3.823137 L17.67811,0.991201 L18.15604,5.839603 L19.90133,0.823565 L20.4133,5.479261 L22.17219,0.750185 L22.66715,5.766222 L24.48946,0.81044 L25.18146,5.692841 L26.52584,0.750186 L26.73651,5.759658 L13.53512,1.533486 L11.99028,4.7872 L15.85238,5.932025 L12.06049,6.293548 L17.26235,8.241366 L10.51349,8.268806 L17.40279,10.591273 L10.46368,11.053615 L17.13893,13.001431 L10.46709,13.711351 L17.32578,14.976685 L10.46369,15.880494 L17.18873,17.45366 L10.39006,18.290652 L17.33258,19.910939 L10.39006,20.399549 L17.19214,21.959589 L10.60074,22.568679 L16.98148,24.188979 L10.46029,25.099359 L17.12191,26.418359 L10.74117,27.509499 L16.63037,27.924709 L10.39006,29.377369 L17.19214,29.250349",
  "M34.23573,28.289539 L40.61407,28.289539 L34.4247,26.210669 L40.33059,26.635889 L34.47195,24.226289 L40.18883,24.604269 L34.47195,22.714389 L40.23608,22.761689 L34.5445,20.709509 L40.18883,21.202539 L34.56408,19.022469 L40.28334,19.548889 L34.47769,17.565918 L40.23608,18.131485 L34.51108,15.809677 L41.60626,16.383354 L34.50537,14.045331 L41.70074,14.682471 L34.46386,12.227021 L40.66132,12.226021 L34.36935,10.401971 L40.56681,9.816421 L34.44428,8.608951 L40.51959,7.832041 L34.31064,6.811191 L40.56681,5.847661 L34.28632,4.739031 L41.18104,4.335761 L34.33021,2.587621 L40.47233,2.209641 L34.09635,0.820891 L41.27791,0.649101 L42.82993,4.015511 L43.65172,0.548871 L44.68167,3.720561 L45.97394,0.871491 L45.91004,3.809321 L48.0346,0.898191 L47.34644,4.053671 L50.28035,1.086191 L48.59681,4.407731 L52.48613,1.538521 L49.68586,4.995661 L54.40566,2.045211 L50.38171,5.316311 L56.26507,3.055001 L51.1262,6.127621 L57.50988,4.854151 L51.49468,6.972331 L57.99973,6.870951 L51.68762,7.672931 L58.06196,8.846811 L52.00943,8.433631 L57.47699,10.555411 L52.13355,9.387251 L56.58265,12.080741 L51.93508,10.266361 L55.27693,13.465731 L51.29852,10.971711 L53.67625,14.039411 L50.16785,11.840331 C50.16785,11.840331 52.49335,14.855463 52.02089,14.855463 C51.54842,14.855463 48.67396,12.913991 48.67396,12.913991 L50.00966,16.049487 L46.99957,13.255611 L45.32473,13.038781 L46.85252,16.314794 L43.37359,12.990951 L43.10877,16.065537 L52.3576,16.751714 L46.4639,17.487688 L53.35214,18.157655 L47.67987,19.303049 L54.57205,19.284849 L48.77887,21.008709 L55.62766,20.741439 L49.7143,22.745949 L56.59651,22.211409 L50.72231,24.410649 L57.53195,23.982069 L51.51839,25.552269 L58.40057,25.218189 L51.91929,26.754989 L59.16898,26.755009 L52.48724,28.024539 L60.17124,28.425449 L52.62086,28.425419",
  "M63.72682,28.754206 L70.60622,28.890206 L64.5057,27.597756 L70.83841,27.632856 L64.9572,26.109596 L71.29802,26.202696 L65.84876,24.188106 L71.90343,24.665896 L66.39337,22.841676 L72.51289,22.327286 L66.87256,21.242806 L73.27556,20.327516 L67.67911,19.257856 L73.15339,18.306766 L68.68473,17.178406 L73.77096,17.164106 L69.36674,15.087446 L74.64433,15.235516 L70.00053,13.297806 L75.44614,13.063946 L70.83574,11.226466 L76.08091,10.792146 L71.47051,9.121716 L76.78249,8.787626 L72.33913,6.883336 L78.21906,6.783106 L73.07413,4.778596 L79.05428,4.110416 L73.70889,2.840886 L79.32155,2.239526 L74.44388,0.70273 L79.42178,0.869774 L82.89628,0.736138 L79.32155,3.174976 L83.26378,2.306346 C83.26378,2.306346 78.28588,5.446756 78.68678,5.246306 C79.08769,5.045856 83.86513,4.511316 83.86513,4.511316 L79.48859,7.451286 L84.76717,6.616076 L80.35722,8.787636 L85.60238,8.988086 C85.60238,8.988086 80.92517,10.825556 81.19244,10.825556 C81.45971,10.825556 86.50442,11.226466 86.50442,11.226466 L81.99425,12.930306 L87.30623,13.631886 L82.42856,15.202096 L88.20826,15.569596 L83.26378,16.905946 L88.77621,17.607526 L83.89854,18.910466 L89.41098,19.244546 L84.53331,21.015216 L90.11256,21.482936 L84.25793,23.659246 L90.78073,23.454046 L85.16807,25.425156 L91.48231,25.391756 L85.80284,27.095606 L92.35094,27.362856 L86.17033,28.799426 L92.81866,28.966466 L82.12788,20.046346 L82.52879,23.788116 L79.78927,20.380436 L79.8895,23.754716 L78.55315,20.514076 L77.61771,23.854946 L77.08317,20.514066 L75.69367,23.606266 L75.04524,20.380516 L73.97616,23.587746",
  "M99.30435,28.773647 L105.68269,28.773647 L99.49332,26.694777 L105.39921,27.119997 L99.54057,24.710397 L105.25745,25.088377 L99.54057,23.198497 L105.3047,23.245797 L99.61312,21.193617 L105.25745,21.686647 L99.6327,19.506577 L105.35196,20.032997 L99.54631,18.050026 L105.3047,18.615593 L99.5797,16.293785 L105.03375,17.113631 L99.57399,14.529439 L106.07188,15.084523 L99.53248,12.711129 L105.72994,12.710129 L99.43797,10.886079 L105.63543,10.300529 L99.5129,9.093059 L105.58821,8.316149 L99.37926,7.295299 L105.63543,6.331769 L99.35494,5.223139 L106.24966,4.819869 L99.39883,3.071729 L105.54095,2.693749 L99.16497,1.304999 L106.34653,1.133209 L107.89855,4.499619 L108.72034,1.032979 L109.75029,4.204669 L111.04256,1.355599 L110.97866,4.293429 L113.10322,1.382299 L112.41506,4.537779 L115.34897,1.570299 L113.66543,4.891839 L117.55475,2.022629 L114.75448,5.479769 L119.47428,2.529319 L115.45033,5.800419 L120.75929,3.703222 L116.19482,6.611729 L121.67588,5.461344 L116.5633,7.456439 L122.00162,7.478139 L116.75624,8.157034 L122.06385,9.248858 L117.07805,8.917734 L121.35579,10.998486 L117.20217,9.871354 L120.91276,12.441759 L117.0037,10.750464 L119.77115,14.524229 L116.36714,11.455814 L118.29356,15.713333 L115.23647,12.324434 C115.23647,12.324434 117.06963,16.775554 116.59717,16.775554 C116.1247,16.775554 113.74258,13.398094 113.74258,13.398094 L114.50388,17.47724 L112.06819,13.739714 L111.37803,13.686994 L112.49553,17.906657 L109.67306,13.680192 L110.43394,17.6574 C110.43994,19.622987 106.73191,14.188087 107.41535,14.035611 L107.59383,18.135901",
  "M129.36189,1.100663 L136.29041,1.022363 L129.59002,3.317903 L136.25127,3.283503 L129.51174,5.468333 L136.03936,5.934083 L129.81143,8.312643 L136.29516,8.750723 L129.96603,10.635853 L136.23981,11.311563 L129.85057,12.812003 L136.19592,13.471493 L130.03006,15.027293 L136.05557,16.032332 L130.11113,17.560433 L136.18446,18.512087 L130.38511,19.816821 L136.18921,20.802871 L130.09491,21.817411 L136.28845,23.347481 L130.41279,24.297171 L136.31138,25.654491 L130.50728,26.444821 L136.35527,28.332261 C136.87608,28.387061 130.67476,28.770311 130.21281,28.343361 C130.4018,27.965381 138.15065,24.173341 138.15065,24.173341 L138.46934,28.488461 L140.29437,24.249661 L140.74391,28.767191 L142.20718,24.111271 L143.26283,28.755731 L144.65928,24.199051 L145.50972,28.801591 L146.94335,24.429151 L147.75465,28.853001 L149.18827,24.205771 L149.78013,28.773911 L151.09771,24.237971 L151.09771,28.714731",
  "M158.07953 29.044862 164.9224 28.674852 158.36465 26.639842 164.9224 26.084852 158.22209 24.049832 164.77983 23.957332 158.36465 21.829822 164.77983 21.367332 158.79233 19.332312 164.92239 18.777322 158.50721 17.019807 164.63727 16.649808 158.50721 14.799798 164.92239 14.707298 158.79233 12.672291 164.63727 12.209788 158.64977 10.26728 164.49471 9.989778 158.50722 7.862268 164.63727 8.139769 158.4182 5.601124 164.81095 5.524074 158.52506 4.311338 164.96243 3.211564 158.36466 1.942241 164.49472 0.739735 158.50722 0.647235",
  "M180.31376,28.974994 L180.79846,29.110222 L175.27404,29.163892 L180.83656,28.396016 L174.97493,27.31395 L180.48853,26.616675 L175.02397,25.069946 L180.78092,24.309718 L174.87882,23.102176 L180.95804,22.327623 L175.16492,21.182636 L181.04545,19.999066 L175.117,18.932622 L181.06518,17.452278 L175.09713,16.924411 L181.20329,15.42358 C181.20329,15.42358 174.80622,15.268428 175.00953,15.029608 C175.21283,14.790788 181.30338,13.265228 181.30338,13.265228 L174.87541,12.619708 C174.87541,12.619708 179.78102,11.103868 181.00083,10.984458 C182.22064,10.865048 174.81927,10.727898 174.81927,10.727898 C174.81927,10.727898 180.90217,9.487908 181.16162,8.658048 C181.22942,8.419238 174.67105,8.311268 174.67105,8.311268 L181.39358,6.119008 L175.01585,6.178608 L181.63914,4.184198 L174.88629,4.249898 L181.95249,2.178298 L174.66774,2.287418 L182.45609,0.674198 L174.61136,0.760398 L183.59568,4.840998 L184.6601,0.567068 L186.18706,5.096788 L187.25688,0.612668 L188.75939,5.122848 C188.75939,5.122848 189.64035,0.399828 189.70811,0.638648 C189.77591,0.877458 191.38726,5.224768 191.38726,5.224768 L191.93827,0.628075 L193.34355,5.072826 C193.28625,4.474576 193.52019,3.260188 194.21372,0.778556 L195.17615,5.11488 L195.35225,0.786998 C195.35225,0.786998 182.74682,2.395658 175.81452,3.000198 L182.48832,15.58358 L183.64796,11.944418 L184.79334,15.73856 L185.76013,11.870488 L187.08295,15.688839 L187.53029,11.910141 L189.13435,15.703069 L189.48996,11.903021 L191.33378,15.772569 L191.48738,11.916975 L193.06204,15.55945 L193.7348,11.982592 L193.7258,15.612317",
  "M226.20767,24.185969 L226.13137,29.034969 L224.37038,24.171739 L223.35293,29.161489 L221.79525,24.171739 L220.64701,29.168599 L219.35563,24.231459 L218.28563,28.991259 L216.83545,24.155509 L215.62768,28.950279 L214.64374,24.215219 L213.006,29.058189 L212.56579,24.461599 L210.68351,28.955039 L210.98765,24.314459 L208.30742,28.887789 L207.906,25.234849 L203.79937,29.032379 L203.57995,24.941549 L202.63629,28.954679 L208.77018,28.368169 L203.01763,24.982739 L209.21764,24.133899 L202.87248,23.014969 L209.284,22.262569 L203.15858,21.095429 L209.3271,19.911859 L203.11066,18.845415 L209.05884,17.365071 L203.09079,16.837204 L209.19695,15.336373 C209.19695,15.336373 202.79988,15.181221 203.00319,14.942401 C203.20649,14.703581 209.29704,13.178021 209.29704,13.178021 L202.86907,12.532501 C202.86907,12.532501 207.77468,11.016661 208.99449,10.897251 C210.2143,10.777841 202.81293,10.640691 202.81293,10.640691 C202.81293,10.640691 208.89583,9.400701 209.15528,8.570841 C209.22308,8.332031 202.66471,8.224061 202.66471,8.224061 L209.38724,6.031801 L203.00951,6.091401 L209.6328,4.096991 L202.87995,4.162691 L209.94615,2.091091 L202.6614,2.200211 L210.44975,0.586991 L202.60502,0.673191 L211.58934,4.753791 L212.65376,0.479861 L214.18072,5.009581 L215.25054,0.525461 L216.75305,5.035641 C216.75305,5.035641 217.63401,0.312621 217.70177,0.551441 C217.76957,0.790251 219.38092,5.137561 219.38092,5.137561 L220.54117,0.395811 L221.48227,5.246721 L223.07183,0.632221 L223.72628,5.081371 L225.66258,0.461521 L225.8076,5.130841 C225.8076,5.130841 210.74048,2.308451 203.80818,2.912991 L210.48198,15.496373 L211.64162,11.857211 L212.787,15.651353 L213.75379,11.783281 L215.23326,15.570303 L215.99389,11.916921 L217.34731,15.584533 L218.17286,11.909801 L219.79738,15.74802 L220.48357,12.080401 L221.83893,15.472243 L222.76232,12.020701 L223.50303,15.583133 L224.89103,12.051091 L224.75739,15.592413",
];

export default function TraplifeTitle({
  donepath,
  offsetTarget,
  time,
  svgStyle,
  gStyle,
  stroke,
  ...props
}) {
  return (
    <svg
      id="traplife"
      version="1.1"
      width="229px"
      height="33px"
      viewBox="0 0 229 33"
      preserveAspectRatio="none"
      style={svgStyle}
      {...props}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        style={gStyle}
      >
        {PATHS.map((path, idx) => {
          const opacityArr = [
            [(idx + 1) * 17, 0],
            [(idx + 1) * 17 + 1, 1],
          ];

          const offsetArr = [
            [0, offsetTarget[idx] - 1],
            [(idx + 1) * 17, offsetTarget[idx] - 1],
            [(idx + 2) * 17, 0],
          ];

          return (
            <path
              id="pathTraplife"
              key={idx}
              ref={(ref) => (donepath[idx] = ref)}
              d={path}
              style={{
                opacity: tween(time, opacityArr),
                fill: "none",
                stroke,
                strokeWidth: 3,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 4,
                strokeOpacity: 1,
                strokeDashoffset: tween(time, offsetArr),
              }}
            />
          );
        })}
      </g>
    </svg>
  );
}
