import React from "react";

import { ReactComponent as ChevronLeft } from "../assets/chevron-left.svg";
import { VIEWS } from "../enums";

export default function Back({ view, setView }) {
  const onGoBack = () => {
    switch (view) {
      case VIEWS.WHITELIST:
        setView(VIEWS.LANDING);
        break;
      case VIEWS.COUNTRY:
        setView(VIEWS.LANDING);
        break;
      case VIEWS.SK:
        setView(VIEWS.COUNTRY);
        break;
      case VIEWS.CZ:
        setView(VIEWS.COUNTRY);
        break;
      default:
        break;
    }
  };

  if (view !== VIEWS.LANDING) {
    return (
      <div onClick={() => onGoBack()} className="back">
        <ChevronLeft fill="#000" />
      </div>
    );
  } else {
    return <></>;
  }
}
