import React, { useState, useEffect } from "react";
import ClipboardJS from "clipboard";

import { TraplifeTitleAnimated } from "../TraplifeTitle";
import MinecraftContent from "./components/MinecraftContent";
import Back from "./components/Back";
import { VIEWS } from "./enums";
import "./style.scss";

const address = "82.208.17.22:27321";

new ClipboardJS(".btn");

export default function Minecraft() {
  const [view, setView] = useState(VIEWS.LANDING);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const copy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1700);
  };

  return (
    <div id="Trapcraft">
      <svg
        style={{ width: "0", height: "0", position: "absolute" }}
        aria-hidden="true"
        focusable="false"
      >
        <linearGradient id="gradient" x1="1" x2="1" y2="1">
          <stop offset="20%" stopColor="#70B237" />
          <stop offset="20%" stopColor="#70B237" />
          <stop offset="30%" stopColor="#854F2B" />
          <stop offset="30%" stopColor="#854F2B" />
        </linearGradient>
      </svg>
      <Back view={view} setView={setView} />
      <div onClick={() => setView(VIEWS.LANDING)} className="traplife-logo">
        <TraplifeTitleAnimated
          stroke="url(#gradient)"
          width="24vh"
          height="4vh"
        />
      </div>
      <MinecraftContent view={view} setView={setView} />
      <h1 className="server">
        {copied ? (
          <div className="copied">Skopírované do schránky</div>
        ) : (
          () => {}
        )}
        <>Server: </>
        <label
          onClick={() => copy()}
          className="btn"
          data-clipboard-action="copy"
          data-clipboard-text={address}
        >
          {address}
        </label>
      </h1>
    </div>
  );
}
